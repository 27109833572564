/*-----------------------------------*\
  AA - Contact
\*-----------------------------------*/

.aa-contact {
	position: relative;
	padding-right: $padding;
	padding-left: $padding;
	max-width: $full-site-width;
	margin-left: auto;
	margin-right: auto;
}

.aa-contact-icon {
	position: fixed;
	z-index: 21;
	right: 5%;
	top: 80%;
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.23);
	background-color: $white;
	border-radius: 50%;
	width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
	justify-content: center;

	@include bp(lg) {
        right: 10%;
        width: 90px;
        height: 90px;
    }

    svg {
        width: 23px;
        height: 23px;

        @include bp(lg) {
            width: 37px;
            height: 27px;
        }
    }
}