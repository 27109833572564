/*-----------------------------------*\
    AA - Form
\*-----------------------------------*/

.aa-form {
    .aa-content {
        @include bp(lg) {
            display: flex;
            flex-direction: row;
        }
    }
}

.aa-form__column {
    @include bp(md) {
        width: calc(100% / 2);
    }

    .hs-button {
        width: 100%;
    }

    .legal-consent-container {
        color: #696969;
    }
}

.aa-form__column__inner {
    width: 100%;

    @include bp(lg) {
        max-width: calc(#{$full-site-width} / 12 * 4);
        margin-left: auto;
        margin-right: auto;
    }
}

.aa-form__header {
    margin-bottom: 20px;

    @include bp(lg) {
        margin-bottom: 30px;
    }
}

.aa-form--one-column {
    .aa-content {
        flex-direction: column;
        align-items: center;
    }

    .aa-form__column__inner {
        width: 100%;

        @include bp(lg) {
            max-width: calc(#{$full-site-width} / 12 * 6);
            margin-left: auto;
            margin-right: auto;
        }
    }

    .aa-form__column {
        &:first-of-type {
            margin-bottom: 30px;
        }
    }
}