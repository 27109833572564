/*-----------------------------------*\
    Article cards
\*-----------------------------------*/

.aa-article-cards {
	padding-left: $padding;
	padding-right: $padding;

	.aa-inner {
		flex-direction: column;
		max-width: $full-site-width;
		margin-left: auto;
		margin-right: auto;
	}
}

.aa-article-cards__header {
	margin-bottom: 50px;

	@include bp(sm) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.aa-article-cards__title {
		margin-bottom: 15px;

		@include bp(sm) {
			margin-bottom: 0;
		}
	}
}

.aa-article-cards__body {
	display: grid;
	grid-template-columns: 1fr;
	max-width: $full-site-width;
	row-gap: 6px;

	@include bp(md) {
		grid-template-columns: repeat(3, 1fr);
		column-gap: 6px;
	}
}

.aa-article-card {
	position: relative;
	display: flex;
	text-decoration: none;
	height: 450px;
	max-width: 450px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	border-bottom: 7px solid $primary;

	@include bp(md) {
		height: 615px;
		max-width: 100%;
	}

	.aa-article-card__date {
		@extend %eiffel-bottom-margin;
	}

	&:hover {

		.aa-article-card__arrow {
			height: 50px;

			svg {
				opacity: 1;
			}
		}

		&:after {
			opacity: 0.2;
		}
	}

	&:after {
		content:"";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: $black;
		opacity: 0.5;
		transition: all ease-in-out 0.3s;
	}
}

.aa-article-card__body {
	position: relative;
	z-index: 12;
	margin-top: auto;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 25px;

	text-decoration: none;
	color: $white;

	@include bp(lg) {
		padding-left: 60px;
		padding-right: 60px;
	}

	.aa-article-card__date {
		@extend .aa-subheading;

		color: $white;
		margin-bottom: 25px;
	}

	.aa-article-card__title {
		margin-top: 0;
		margin-bottom: 25px;
	}

	.aa-article-card__arrow {
		height: 0;
		transition: all ease-in-out 0.3s;

		svg {
			transition: all ease-in-out 0.3s;
			opacity: 0;
			fill: $white;
			margin-top: 30px;
		}
	}
}