/*-----------------------------------*\
    AA - Info Columns
\*-----------------------------------*/

.aa-info-columns {
	.aa-inner {
		max-width: $full-site-width;
		flex-direction: column;
		margin-left: auto;
		margin-right: auto;
	}
}

.aa-info-column {
	display: flex;
	flex-direction: column;

	@include bp(md) {
		display: block;
	}

	img {
		transition: filter ease-in-out 0.3s;
		filter: grayscale(1);
	}

	&:hover {
		img {
			filter: grayscale(0);
		}
	}

	.aa-bg-image-container {
		position: relative;

		@include bp(md) {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.aa-info-column--1 {
	position: relative;
	width: 100%;
	padding-bottom: 20px;

	@include bp(md) {
		margin-top: 120px;
		padding-bottom: 140px;
	}

	.aa-info-column__text {
		position: relative;
		z-index: 2;
		max-width: calc(#{$full-site-width} / 12 * 6);
		margin-right: 20px;
		margin-left: 20px;
		margin-top: -40px;
		padding: 40px 20px;

		background-color: $white;

		@include bp(md) {
			margin-top: -120px;
		}

		@include bp(xl) {
			margin-right: 120px;
			margin-left: auto;
			padding: 80px;
			padding-top: 40px;
		}

		.aa-button-group {
			margin-top: 30px;
		}
	}
}

.aa-info-column--2 {
	position: relative;
	width: 100%;
	padding-top: 20px;
	margin-top: 20px;

	@include bp(md) {
		padding-top: 140px;
		margin-bottom: 120px;
	}

	@include bp(lg) {
		margin-top: 0;
	}

	.aa-info-column__text {
		position: relative;
		z-index: 2;
		max-width: calc(#{$full-site-width} / 12 * 6);
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 0;
		margin-top: -40px;
		padding: 40px 20px;

		background-color: $white;

		@include bp(md) {
			margin-bottom: -120px;
			margin-top: 0;
		}

		@include bp(xl) {
			margin-left: 120px;
			margin-right: auto;
			padding: 80px;
			padding-bottom: 40px;
		}

		.aa-button-group {
			margin-top: 30px;
		}
	}
}

.aa-info-columns--reversed {
	.aa-info-column--1 {
		.aa-info-column__text {
			@include bp(xl) {
				margin-left: 120px;
				margin-right: auto;
			}
		}
	}

	.aa-info-column--2 {
		.aa-info-column__text {
			@include bp(xl) {
				margin-right: 120px;
				margin-left: auto;
			}
		}
	}
}