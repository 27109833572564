/*-----------------------------------*\
  Tags
\*-----------------------------------*/

.aa-tags__inner {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    @include bp(xl) {
        max-width: calc(#{$full-site-width} / 12 * 10);
        margin-left: auto;
        margin-right: auto;
    }
}

.aa-tag {
    display: flex;
    padding: 16px 34px;
    margin-bottom: 15px;

    font-size: 12px;
    line-height: 16px;

    text-decoration: none;
    color: $white;
    font-weight: $weight-700;
    background-color: $primary;
    border-radius: 100px;

    &:not(:last-of-type) {
        margin-right: 15px;
    }

    &:hover {
        text-decoration: none;

        color: $white;
        background-color: $complementary;
    }
}

.aa-tag--current {
    background-color: $complementary;
    color: $white;
}