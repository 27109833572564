/*-----------------------------------*\
    AA - Hero Column
\*-----------------------------------*/

.aa-hero-column {
	.aa-inner {
		max-width: calc(#{$full-site-width} / 12 * 10);
        margin-left: auto;
		margin-right: auto;
		padding-top: 0;
		padding-bottom: 0;
	}

	.aa-content {

		@include bp(lg) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

.aa-hero-column__header {
	max-width: calc(#{$full-site-width} / 12 * 5.5);

	@include bp(lg) {
		padding-top: 35px;
		padding-bottom: 35px;
	}

	.aa-hero-column__header__content {

		@include bp(lg) {
			max-width: calc(#{$full-site-width} / 12 * 4.25);
		}
	}
}

.aa-hero-column__body {
	position: relative;
	width: 100%;
	min-height: 420px;
	margin-top: 30px;

	@include bp(lg) {
		max-width: calc(#{$full-site-width} / 12 * 4);
		margin-top: 0;
		margin-left: 45px;
	}
}

.aa-hero-column.aa-bg-overlay--dark {
	&:before {
		background: linear-gradient(76.45deg, rgba(0, 0, 0, 0.8) 18.97%, rgba(0, 0, 0, 0) 80.38%), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
	}
}

.aa-hero-column.aa-bg-overlay--primary {
	&:before {
		background: linear-gradient(76.45deg, rgba($primary, 0.8) 18.97%, rgba($primary, 0) 80.38%), linear-gradient(0deg, rgba($primary, 0.6), rgba($primary, 0.6));
	}
}

.aa-hero-column.aa-bg-overlay--secondary {
	&:before {
		background: linear-gradient(76.45deg, rgba($secondary, 0.8) 18.97%, rgba($secondary, 0) 80.38%), linear-gradient(0deg, rgba($secondary, 0.6), rgba($secondary, 0.6));
	}
}

.aa-hero-column.aa-bg-overlay--complementary {
	&:before {
		background: linear-gradient(76.45deg, rgba($complementary, 0.8) 18.97%, rgba($complementary, 0) 80.38%), linear-gradient(0deg, rgba($complementary, 0.6), rgba($complementary, 0.6));
	}
}

.aa-hero-column.aa-bg-overlay--light {
	&:before {
		background: linear-gradient(76.45deg, rgba($light, 0.8) 18.97%, rgba($light, 0) 80.38%), linear-gradient(0deg, rgba($light, 0.6), rgba($light, 0.6));
	}
}

.aa-hero-column.aa-bg-overlay--white {
	&:before {
		background: linear-gradient(76.45deg, rgba($white, 0.8) 18.97%, rgba($white, 0) 80.38%), linear-gradient(0deg, rgba($white, 0.6), rgba($white, 0.6));
	}
}