/*-----------------------------------*\
    AA - Products
\*-----------------------------------*/

.aa-products {
	.aa-inner {
		flex-direction: column;
	}
}

.aa-products__header {
	text-align: center;
	margin-bottom: 70px;

	@include bp(lg) {
        max-width: calc(#{$full-site-width} / 12 * 4);
        margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
    }
}

.aa-products__body {
    width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 20px;

	@include bp(sm) {
		grid-template-columns: repeat(2, 1fr);
        max-width: 100%;
		column-gap: 12px;
		row-gap: 44px;
	}

	@include bp(lg) {
		grid-template-columns: repeat(4, 1fr);
	}
}

.aa-product {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	overflow: hidden;
	padding-top: 20px;
	background: linear-gradient(180deg, transparent 8%, rgba(243,243,243,1) 8%);
	border-bottom: 5px solid $primary;
	text-align: center;
	text-decoration: none;
	font-weight: $weight-600;

	&:hover {
		.aa-product__header {
			img {
				transform: scale(1.05);
			}
		}
	}
}

.aa-product__header {
	transition: all ease-in-out 0.3s;

	img {
		transition: all ease-in-out 0.3s;
		width: 100%;
		height: auto;

		@include bp(lg) {
			max-height: 380px;
		}
	}
}

.aa-product__body {
	margin-bottom: 45px;
	padding-left: 20px;
	padding-right: 20px;
}