/*-----------------------------------*\
    AA - Panels
\*-----------------------------------*/

.aa-panels {
    padding-left: 0;
    padding-right: 0;
    padding-top: 100px;

    @include bp(lg) {
        padding-top: 0;
    }
}

.aa-panels__inner {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include bp(lg) {
        flex-direction: row;
    }
}

.aa-panels__header,
.aa-panels__body {
    @extend %burj-bottom;

    position: relative;
    width: 100%;
    padding-top: 40px;
    padding-left: $padding;
    padding-right: $padding;

    @include bp(lg) {
        padding-top: 200px;
    }
    @include bp(hg) {
        padding-left: 0;
        padding-right: 0;
    }
}

.aa-panels__header-content {
    max-width: 100%;
    width: 100%;

    @include bp(sm) {
        max-width: calc(#{$full-site-width} / 12 * 4);
        margin-left: auto;
        margin-right: auto;
    }

    @include bp(lg) {
        max-width: 550px;
        margin-right: 80px;
    }
}

.aa-panels__body-content {
    position: relative;
    z-index: 12;
    background-color: $white;

    @include bp(sm) {
        max-width: calc(#{$full-site-width} / 12 * 4);
        margin-left: auto;
        margin-right: auto;
    }

    @include bp(lg) {
        max-width: calc(#{$full-site-width} / 12 * 5);
    }
}

.aa-panels__form {
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    width: 100%;

    color: $black;
    background-color: $white;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

    @include bp(lg) {
        padding: 60px 50px;
    }

    .hs-form input[type=submit] {
        width: 100%;
    }

    .aa-panels__form__header {
        margin-bottom: 20px;
        text-align: center;
        color: $black;
    }

    .hs-form,
    .hs-form-field,
    .hs-form .hs-richtext p {
        color: $black;
    }

    /* Custom checkbox */
    .aa-content-color--dark ul.inputs-list.multi-container li.hs-form-checkbox,
    .aa-content-color--dark ul.inputs-list li.hs-form-booleancheckbox,
    ul.inputs-list.multi-container li.hs-form-checkbox,
    ul.inputs-list li.hs-form-booleancheckbox {

        margin: 16px 0;

        label {
            position: relative;
            display: flex;
        }

        label span:not(.hs-form-required) {
            display: inline-block;
            margin-left: 30px;
            margin-right: 16px;
        }

        // Resize and hide the original input
        label input {
            height: 20px;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 20px;
        }

        // Add new input
        label span:not(.hs-form-required)::before {
            border: 1px solid;
            content: "";
            height: 20px;
            left: 0;
            position: absolute;
            top: 0;
            width: 20px;

            border: 1px solid $primary;
            border-radius: 2px;
            background-size: 100% 1px;
            background-position: 0 0, 0 100%;
            background-repeat: no-repeat;

        }

        // Checked item
        label span:not(.hs-form-required)::after {
            content: "";
            height: 14px;
            width: 14px;
            border: 1px solid $primary;
            border-radius: 2px;
            left: 3px;
            opacity: 0;
            position: absolute;
            background: $primary;
            top: 3px;
            transition: opacity 0.2s ease-in-out;
            cursor: pointer;
        }

        // Show checked style
        label input:checked + span:not(.hs-form-required)::after {
            opacity: 1;
        }

        // Focused state
        label input:focus + span:not(.hs-form-required)::after {
            outline: 3px solid transparent; /* For Windows high contrast mode. */
        }

    }

    /* Custom radio button */
    .aa-bg-image .aa-panels__form ul.inputs-list.multi-container li.hs-form-radio,
    ul.inputs-list.multi-container li.hs-form-radio {
        margin: 16px 0;

        label {
            position: relative;
        }

        label span {
            display: inline-block;
            margin-left: 30px;
            margin-right: 16px;
        }

        // Resize and hide the original input
        label input {
            height: 20px;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 20px;
        }

        // Add new input
        label span::before {
            border: 1px solid;
            content: "";
            height: 20px;
            left: 0;
            position: absolute;
            top: 0;
            width: 20px;
            border-radius: 50%;
        }

        // Checked item
        label span::after {
            content: "";
            height: 14px;
            width: 14px;
            left: 3px;
            position: absolute;
            background-color: $white;
            top: 3px;
            border-radius: 50%;
            -webkit-transition: background-color .2s ease-in-out;
            transition: background-color .2s ease-in-out;
            cursor: pointer;
            border: 1px solid $white;// Inset the selection from gradient border
        }

        // Show checked style
        label input:checked + span::after {
            background-color: $primary;
            border: 1px solid $primary;
        }

        // Focused state
        label input:focus + span::after {
            outline: 3px solid transparent; /* For Windows high contrast mode. */
        }

    }

}