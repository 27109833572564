/*-----------------------------------*\
    Card slider
\*-----------------------------------*/

.aa-card-slider {
	overflow: hidden;

	.aa-faq-card {
		min-height: 450px;
		display: flex;
		align-items: center;
		justify-content: center;

		.aa-faq-card__body {
			flex: 0;
		}
	}
}

.aa-card-slider__header {
	max-width: $full-site-width;
	margin-bottom: 45px;

	@include bp(md) {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	.aa-card-slider__header__text {
		max-width: calc(#{$full-site-width} / 12 * 4);
	}

	.aa-button {
		align-self: flex-end;
		margin-top: 20px;

		@include bp(lg) {
			margin-right: 10%;
			margin-top: 0;
		}
	}
}

.aa-card-slider__body {
	max-width: $full-site-width;
	margin-left: auto;

	.splide__slide {
		max-width: 480px;
		opacity: 0.3;
	}

	.splide__slide.is-visible {
		opacity: 1;
	}

	.splide__track {
		position: relative;
		overflow: visible;
	}

	button:disabled {
		svg {
			fill: rgba($black, 0.2);
		}
	}

	.splide__arrows {
		position: absolute;
		top: -25px;
		right: 50px;

		@include bp(lg) {
			top: -70px;
		}
	}

	.splide__arrow {
		background: transparent;
		left: 0;
		right: 0;
		opacity: 1;
		width: 47px;

		svg {
			width: 47px;
			fill: $primary;
		}
	}

	.splide__arrow--prev {
		left: -60px;

		svg {
			transform: scaleX(1);
		}
	}
}