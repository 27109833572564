/*-----------------------------------*\
  Blog index
\*-----------------------------------*/

@import '../components/tags';
@import '../components/pagination';
@import '../modules/blog-header';

/*-----------------------------------*\
  Blog Header
\*-----------------------------------*/

.aa-blog-header {
    width: 100%;
    margin-bottom: 20px;
}

.aa-blog-header__inner {
    @extend %empire-top;

    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: $padding;
    padding-right: $padding;

    @include bp(lg) {
        max-width: $full-site-width;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
    }
    @include bp(hg) {
        padding-left: 0;
        padding-right: 0;
    }
}

.aa-blog-header__content {
    max-width: calc(#{$full-site-width} / 12 * 3.5);
    width: 100%;
}