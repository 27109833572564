/*-----------------------------------*\
    AA - Focus box
\*-----------------------------------*/

.aa-focus-box {
    position: relative;
    flex-direction: column;
    justify-content: center;

    &.aa-bg-overlay-gradient--primary:before {
        background: linear-gradient(90deg, rgba($primary-rgb, 0.9) 25.55%, rgba($primary-rgb, 0.5) 100%);
    }
    &.aa-bg-overlay-gradient--primary {
        .aa-button--4 {
            border: 1px solid $white;

            &:hover {
                background-color: $white;
                color: $primary;
            }
        }
    }
    &.aa-bg-overlay-gradient--secondary:before {
        background: linear-gradient(90deg, rgba($secondary-rgb, 0.9) 25.55%, rgba($secondary-rgb, 0.5) 100%);
    }
    &.aa-bg-overlay-gradient--complementary:before {
        background: linear-gradient(90deg, rgba($complementary-rgb, 0.9) 25.55%, rgba($complementary-rgb, 0.5) 100%);
    }
    &.aa-bg-overlay-gradient--light:before {
        background: linear-gradient(90deg, rgba($light-rgb, 0.9) 25.55%, rgba($light-rgb, 0.5) 100%);
    }
    &.aa-bg-overlay-gradient--dark:before {
        background: linear-gradient(257.21deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 74.31%);
    }
    &.aa-bg-overlay-gradient--white:before {
        background: linear-gradient(90deg, rgba($white, 0.9) 25.55%, rgba($white, 0.5) 100%);
    }
    &.aa-bg-overlay-gradient--extra-dark {
        color: $dark;

        @include bp(lg) {
            color: $white;
        }
    }
    &.aa-bg-overlay-gradient--extra-dark:before {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    }

    .aa-inner {
        max-width: calc(#{$full-site-width} / 12 * 10);
        align-items: center;
    }
}

.aa-focus-box__header,
.aa-focus-box__body {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.aa-focus-box__body {
    margin-bottom: 40px;
}

.aa-focus-box--tall {
    min-height: 735px;
}

.aa-focus-box--normal {
    min-height: 570px;
}

.aa-focus-box--default {
    min-height: 0;
}

.aa-focus-box--mobile-version {

    &.aa-bg-overlay-gradient--primary:before {
        background: none;

        @include bp(lg) {
            background: linear-gradient(90deg, rgba($primary-rgb, 0.9) 25.55%, rgba($primary-rgb, 0.5) 100%);
        }
    }
    &.aa-bg-overlay-gradient--secondary:before {
        background: none;

        @include bp(lg) {
            background: linear-gradient(90deg, rgba($secondary-rgb, 0.9) 25.55%, rgba($secondary-rgb, 0.5) 100%);
        }
    }
    &.aa-bg-overlay-gradient--complementary:before {
        background: none;

        @include bp(lg) {
            background: linear-gradient(90deg, rgba($complementary-rgb, 0.9) 25.55%, rgba($complementary-rgb, 0.5) 100%);
        }
    }
    &.aa-bg-overlay-gradient--light:before {
        background: none;

        @include bp(lg) {
            background: linear-gradient(90deg, rgba($light-rgb, 0.9) 25.55%, rgba($light-rgb, 0.5) 100%);
        }
    }
    &.aa-bg-overlay-gradient--dark:before {
        background: none;

        @include bp(lg) {
            background: linear-gradient(257.21deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 74.31%);
        }
    }
    &.aa-bg-overlay-gradient--white:before {
        background: none;

        @include bp(lg) {
            background: linear-gradient(90deg, rgba($white, 0.9) 25.55%, rgba($white, 0.5) 100%);
        }
    }
    &.aa-bg-overlay-gradient--extra-dark {
        color: $dark;

        @include bp(lg) {
            color: $white;
        }
    }
    &.aa-bg-overlay-gradient--extra-dark:before {
        background: none;

        @include bp(lg) {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
        }
    }

    .aa-focus-box--image {
        margin-bottom: 20px;

        @include bp(lg) {
            margin-bottom: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.aa-focus-box--mobile-version.aa-content-color--light {
    color: $black;

    @include bp(lg) {
        color: $white;
    }
}