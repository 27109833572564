/*-----------------------------------*\
    AA - Employee Contact
\*-----------------------------------*/

.aa-employee-contact {
	flex-direction: column;

	.aa-employee-contact__title__container {
		text-align: center;
		color: $primary;

		@include bp(lg) {
            max-width: calc(#{$full-site-width} / 12 * 10);
            margin-left: auto;
            margin-right: auto;
        }
	}

	.aa-inner {
		position: relative;
		background: $secondary;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
		margin-top: 70px;

		@include bp(lg) {
            max-width: calc(#{$full-site-width} / 12 * 10);
            margin-left: auto;
            margin-right: auto;
        }
	}
	.aa-content {
		position: relative;
		z-index: 6;

		@include bp(md) {
			display: flex;
		}
	}
}

.aa-employee-contact__header {
	margin-top: -70px;

	@include bp(md) {
		width: 532px;
		height: 532px;
	}
}

.aa-employee-contact__body {
	max-width: calc(#{$full-site-width} / 12 * 5);
	width: 100%;
	margin-left: auto;
	background-color: $white;
	padding: 20px;

	@include bp(sm) {
		padding-top: 70px;
		padding-bottom: 50px;
		padding-left: 100px;
		padding-right: 100px;
	}
	@include bp(md) {
		margin-top: 110px;
	}
}

.aa-employee-contact__name {
	@extend h2;

	display: block;
	margin-bottom: 0;
}

.aa-employee-contact__title {
	color: $primary;
}

.aa-employee__info {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	color: $black;

	svg {
		margin-right: 15px;
	}

	a {
		color: $black;
		text-decoration: none;
	}
}

.aa-employee-contact__item {
	display: flex;
	align-items: center;
	color: $primary;
	text-transform: uppercase;
	margin-bottom: 20px;
	font-weight: $weight-600;

	svg {
		margin-right: 35px;
		width: 23px;
	}

	a {
		color: $primary;
		text-decoration: none;
	}
}

/*
* Modifier
*/

.aa-employee-contact--reversed {
	.aa-content {
		@include bp(md) {
			flex-direction: row-reverse;
		}
	}
	.aa-employee-contact__body {
		margin-right: auto;
		margin-left: 0;
	}
}