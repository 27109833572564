/*-----------------------------------*\
    Alternative Columns
\*-----------------------------------*/

.aa-alternative-columns {

	.aa-inner {
		flex-direction: column;
		justify-content: flex-end;
		max-width: $full-grid;
		margin-left: auto;
	}
}

.aa-alternative-columns__body {

	@include bp(md) {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		max-width: $full-site-width;
		width: 100%;
		margin-left: auto;
	}
}

.aa-alternative-columns__text {
	max-width: calc(#{$full-site-width} / 12 * 3);

	@include bp(hg) {
		max-width: calc(#{$full-site-width} / 12 * 4);
	}
}

.aa-alternative-column__image {
	max-width: 875px;
	width: 100%;
	margin-top: 30px;

	@include bp(sm) {
		display: flex;
	}

	@include bp(md) {
		margin-top: 0;
		margin-left: 45px;
	}

	@include bp(hg) {
		max-width: 875px;
		margin-left: 65px;
	}

	@include bp(xxl) {
		max-width: calc(#{$full-site-width} / 12 * 8);
	}
}

.aa-alternative-column__image-container1 {
	max-width: 315px;
	width: 100%;

	@include bp(sm) {
		margin-right: 22px;
	}

	.image1 {
		max-width: 315px;
		width: 100%;
		display: none;

		@include bp(sm) {
			display: block;
		}
	}
}

.aa-alternative-column__image-container2 {
	max-width: 540px;
	width: 100%;
	margin-top: 22px;

	@include bp(sm) {
		margin-top: 0;
	}

	.image2 {
		max-width: 370px;
		width: 100%;
		display: none;

		@include bp(sm) {
			margin-bottom: 22px;
			display: block;
		}
	}

	.image3 {
		max-width: 540px;
		width: 100%;
		margin-top: 22px;

		@include bp(sm) {
			margin-top: 0;
		}
	}
}

/*
* Modifiers
*/

.aa-alternative-columns--reversed {
	.aa-alternative-columns__body {

		@include bp(md) {
			flex-direction: row-reverse;
			justify-content: flex-start;
			margin-left: 0;
			margin-right: auto;
		}
	}

	.aa-alternative-column__image {

		@include bp(sm) {
			flex-direction: row-reverse;
		}

		@include bp(md) {
			margin-left: 0;
			margin-right: 45px;
		}

		@include bp(hg) {
			margin-left: 0;
			margin-right: 65px;
		}
	}

	.aa-alternative-column__image-container1 {

		@include bp(sm) {
			margin-right: 0;
			margin-left: 22px;
		}
	}

	.aa-alternative-column__image-container2 {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
}

.aa-alternative-columns--one-image {
	.aa-alternative-column__image-container1 {
		max-width: 875px;
		width: 100%;

		.image1 {
			max-width: 875px;
			width: 100%;
		}
	}
}