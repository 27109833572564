/*-----------------------------------*\
    Buttons
\*-----------------------------------*/

.aa-button {
    appearance: none;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    margin: 0;
    padding: 0;
    border-style: solid;

    font-size: 16px;
    line-height: 1;
    font-family: $button-font-family;
    font-style: $button-font-style;
    font-weight: $button-font-weight;
    text-decoration: none;
    text-align: center;

    background: transparent;
    cursor: pointer;
    transition: all $speed $animation;

    @include bp(lg) {
        font-size: 18px;
    }

    &:hover, &:focus, &:active {
        outline: 0;
        box-shadow: $box-shadow;
    }
}

a.aa-button {
    text-decoration: none; // Override text decorations applied directly to <a> elements that are buttons
}

/*
 * Theme buttons
 */
@each $button, $properties in $buttons {
    .aa-button--#{$button} {
        padding: map-get($properties, "padding");
        border-color: map-get($properties,"border-color");
        border-radius: map-get($properties, "border-radius");
        border-width: map-get($properties, "border-width");

        color: map-get($properties,"color");

        background-color: map-get($properties,"background-color");

        &:hover, &:focus, &:active {
            $properties: map-get($properties, "hover");

            border-color: map-get($properties,"border-color");

            color: map-get($properties,"color");

            background-color: map-get($properties,"background-color");
        }
    }

    a.aa-button--#{$button} {
        @extend .aa-button--#{$button};
    }

    .aa-form-button--#{$button} input[type="submit"] {
        @extend .aa-button--#{$button};
    }

    @each $key, $color in $main-colors {
        .aa-bg-#{$key} {
            a.aa-button--#{$button} {
                @extend .aa-button--#{$button};
            }
        }
    }
}

// Individual button overrides
.aa-button--1 {
    // Custom overrides for this button go here
}

// HubSpot default button class
.hs-button {
    @extend .aa-button;
    @extend .aa-button--1;
}

/*
* Button groups
*/
.aa-button-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include bp(sm) {
        flex-direction: row;
    }
}

.aa-button + .aa-button {
    margin-top: 20px;

    @include bp(sm) {
        margin-top: 0;
        margin-left: 20px;
    }
}