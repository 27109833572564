/*-----------------------------------*\
    Accordion
\*-----------------------------------*/

.aa-accordion {
    .aa-content {
        max-width: calc(#{$full-site-width} / 12 * 8);
    }
}

.aa-accordion__header {
    @extend %eiffel-bottom;

    max-width: calc(#{$full-site-width} / 12 * 6);
    margin-left: auto;
    margin-right: auto;

    text-align: center;
}

// Single accordion item
.aa-accordion-item {
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.aa-accordion-item__header,
.aa-accordion-item__body {
    padding-left: $padding;
    padding-right: $padding;

    @include bp(md) {
        padding-left: calc(#{$full-site-width} / 12 * 0.25);
        padding-right: calc(#{$full-site-width} / 12 * 0.25);
    }
}

.aa-accordion-item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    user-select: none;

    h5 {
        margin-bottom: 0;
    }

    &.is-active {
        .aa-accordion-readmore {
            &:before {
                display: none;
            }

            &:after {
                transform: rotate(180deg);
            }
        }
    }
}

.aa-accordion-readmore {
    position: relative;
    background-color: $primary;
    width: 28px;
    height: 28px;

    &:before, &:after {
        content: "";
        position: absolute;
        right: 9px;
        top: calc(50% - 1px);
        width: 10px;
        height: 2px;

        background-color: $white;
    }

    &:after {
        transform: rotate(90deg);
        transition: transform .5s ease;
    }
}

.aa-accordion-item__body {
    display: none;
    max-width: calc(#{$full-site-width} / 12 * 8);
    padding-bottom: 40px;
}

.aa-content-color--light .aa-accordion-item__header {
    &:before, &:after {
        background-color: $text-dark;
    }
}

.aa-content-color--dark .aa-accordion-item__header {
    &:before, &:after {
        background-color: $text-light;
    }
}

.aa-accordion-item.aa-bg-primary {
    .aa-accordion-readmore {
        background-color: $white;

        &:before, &:after {
            content: "";
            background-color: $primary;
        }
    }
}
