/*-----------------------------------*\
    Navigation
\*-----------------------------------*/

.aa-navigation-container {
    display: flex;
    align-items: center;

    @include bp(lg) {
        margin-left: auto;
    }
}

.aa-navigation {
    ul, li { // Reset
        list-style: none;
        padding: 0;
        margin: 0;
    }

    // ul {
    //     display: flex;
    //     align-items: center;
    // }

    ul li {}

    ul li a {
        display: block;

        text-decoration: none;
    }
}

.aa-navigation--primary {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @include bp(md) {
        flex-direction: row;
    }
}

.aa-navigation--primary .hs-menu-wrapper > ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: inherit;

    list-style: none;
}

.aa-navigation--primary .hs-menu-wrapper > ul > li {
    height: inherit;

    &:last-of-type {
        padding-left: 10px;
        padding-right: 10px;

        a {
            text-align: center;
            max-width: 300px;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
            text-transform: initial;
            color: $primary;
            padding: 16px 34px;
            border: 1px solid $primary;
            border-radius: 30px;

            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            &:hover, &:focus, &:active {
                outline: 0;
                box-shadow: $box-shadow;
            }

            &:hover {
                color: $complementary;
                background-color: transparent;
                border: 1px solid $complementary;
            }
        }
    }
}

.aa-navigation--primary .hs-menu-wrapper > ul > li > a {
    display: flex;
    align-items: center;
    height: inherit;

    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    font-weight: $weight-400;

    color: inherit;

    &:hover {
        color: $primary;
    }

    @include bp(lg) {
        margin-right: 25px;
    }

    @include bp(hg) {
        margin-right: 30px;
    }
}
.aa-navigation--primary .hs-menu-depth-1 {
    &:last-of-type {
        a {
            margin-right: 0;
            text-transform: initial;
            color: $primary;
            padding: 16px 34px;
            border: 1px solid $primary;
            border-radius: 30px;
            height: 45px;

            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
        }
    }
}
.aa-navigation--primary .hs-menu-depth-1.hs-item-has-children {
    position: relative;
}

.aa-navigation--primary .hs-menu-depth-1.hs-item-has-children > .hs-menu-children-wrapper {
    @include bp(lg) {
        display: none;
        flex-wrap: wrap;
        width: auto;
        position: absolute;
        top: calc(100% + 20px);

        background: $white;
        box-shadow: $box-shadow;

        &:before {
            display: block;
            position: absolute;
            content: '';
            top: -20px;
            left: 0;
            right: 0;
            height: 20px;
        }
    }

    @include bp(xl) {
        flex-wrap: nowrap;
        width: auto;
    }
    li {
        padding-left: 0;
    }
    a {
        flex-direction: column;

        @include bp(lg){
            width: 250px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
        }
        &:hover {
            background-color: rgba($primary, 0.1);
            border-bottom: 0;
        }
        p:first-of-type {
            display: flex;
            margin-bottom: 5px;
            border-bottom: 1px solid $light;

        }
        p {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 42px;
        }
        &:after {
            content:'';
            background-image: none;
        }
    }
}

.aa-navigation--primary .hs-menu-depth-1:hover > .hs-menu-children-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;

    @include bp(lg) {
        display: flex;
        flex-direction: column;
    }
}







html {
    transition: transform $speed ease;
}
html.js-nav-open {
    // height: 100%;

    // overflow-y: hidden;
    // transform: translateX(-80%);
}
body.js-nav-open {
    overflow-y: hidden;
}

.aa-navigation--mobile {
    width: 100%;
    min-height: 100vh;
    position: fixed;
    bottom: 100%;
    right: 0;

    font-size: 16px;
    line-height: 24px;

    background-color: $white;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    z-index: 998;

    @include bp(md) {
        padding-top: 50px;
    }
}

.aa-navigation--mobile ul {
    flex-direction: column;
}

.aa-navigation--mobile ul li {
    padding-top: 30px;
    padding-bottom: 30px;
}

.js-nav-open .aa-navigation--mobile {
    top: 80px;
    bottom: 0;

    @include bp(lg) {
        top: 150px;
    }
}

.aa-navigation--mobile ul {
    // Reset
    padding: 0;
    margin: 0;
    list-style: none;
    padding-top: 50px;
    padding-bottom: 50px;
}
.aa-navigation--mobile ul li {
    // Reset
    padding: 0;
    margin: 0;
}
.aa-navigation--mobile ul li a {
    display: block;
    height: 60px;

    color: $black;
    text-decoration: none;
}

.aa-navigation--mobile .hs-menu-depth-1 {
    border-bottom: 1px solid #eee;

    > a {
        padding: 13px 20px;

        font-size: 16px;
        line-height: 24px;
        font-weight: $weight-400;
        text-transform: uppercase;
    }

    &:last-of-type {
        padding-left: 10px;
        padding-right: 10px;
        border-bottom: none;

        a {
            text-align: center;
            max-width: 300px;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
            text-transform: initial;
            color: $primary;
            padding: 16px 34px;
            border: 1px solid $primary;
            border-radius: 30px;

            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            &:hover, &:focus, &:active {
                outline: 0;
                box-shadow: $box-shadow;
            }

            &:hover {
                color: $complementary;
                background-color: transparent;
                border: 1px solid $complementary;
            }
        }
    }

    > ul {
        display: none;
        background: $primary;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &.is-active > ul {
        display: block;
    }
}


.aa-navigation--mobile .hs-menu-depth-2 {
    a {
        padding: 5px 20px;
        color: $white;
    }

    > ul {
        display: none;
        padding: 5px 20px;

        color: $white;
        background-color: $primary;
    }

    &.is-active > ul {
        display: block;
    }
}

.aa-navigation--mobile .hs-item-has-children {
    position: relative;

    > a {
        display: flex;
        align-items: center;
    }
}

.aa-menu-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;

    transition: all $speed $animation;
}

.hs-item-has-children.is-active .aa-menu-arrow svg {
    transform: rotate(90deg);
}