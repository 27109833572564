/*-----------------------------------*\
    Utilities
\*-----------------------------------*/

.aa-bg-image {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

.aa-bg-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.aa-object-cover,
.aa-object-contain {
    img {
        height: 100%;
        width: 100%;
    }
}

.aa-object-cover img {
    object-fit: cover;
}

.aa-object-contain img {
    object-fit: contain;
}

/*
 * Background colors
 */
.aa-bg-primary {
    color: $primary-bg-text;

    .aa-label,
    .aa-subheading {
        color: $primary;
    }

    a {
        color: $primary-bg-link;
        text-decoration: underline;

        &:hover, &:active, &:focus {
            color: $primary-bg-link-hover;
        }
    }

    .hs-input {
        border-color: $primary-bg-accent;
    }

    .aa-article-card {
        text-decoration: none;
    }
}

.aa-bg-secondary {
    color: $secondary-bg-text;

    .aa-label,
    .aa-subheading {
        color: $primary;
    }

    a {
        color: $secondary-bg-link;
        text-decoration: underline;

        &:hover, &:active, &:focus {
            color: $secondary-bg-link-hover;
        }
    }

    .hs-input {
        border-color: $secondary-bg-accent;
    }

    .aa-article-card {
        text-decoration: none;
    }
}

.aa-bg-complementary {
    color: $complementary-bg-text;

    .aa-label,
    .aa-subheading {
        color: $primary;
    }

    a {
        color: $complementary-bg-link;
        text-decoration: underline;

        &:hover, &:active, &:focus {
            color: $complementary-bg-link-hover;
        }
    }

    .hs-input {
        border-color: $complementary-bg-accent;
    }

    .aa-article-card {
        text-decoration: none;
    }
}

.aa-bg-light {
    color: $light-bg-text;

    .aa-label,
    .aa-subheading {
        color: $primary;
    }

    a {
        color: $light-bg-link;
        text-decoration: underline;

        &:hover, &:active, &:focus {
            color: $light-bg-link-hover;
        }
    }

    .hs-input {
        border-color: $light-bg-accent;
    }

    .aa-article-card {
        text-decoration: none;
    }
}

.aa-bg-dark {
    color: $dark-bg-text;

    .aa-label,
    .aa-subheading {
        color: $primary;
    }

    a {
        color: $dark-bg-link;
        text-decoration: underline;

        &:hover, &:active, &:focus {
            color: $dark-bg-link-hover;
        }
    }

    .hs-input {
        border-color: $dark-bg-accent;
    }

    .aa-article-card {
        text-decoration: none;
    }
}

/*
 * Generate utility classes and styling for all background colors defined in $colors
 */
@each $key, $color in $main-colors {
    .aa-bg-#{$key} {
        background-color: $color;
    }

    .aa-bg-overlay--#{$key} {
        @extend .aa-bg-#{$key};

        &:before {
            background: linear-gradient(0deg, rgba($color, 0.41), rgba($color, 0.41));
        }
    }

    .aa-content-color--#{$key} {
        @if $key == 'light' {
            color: $text-light;

            .aa-subheading,
            .aa-label {
                color: $white;
            }
        } @else if $key == 'dark' {
            color: $text-dark;
        } @else {
            color: $color;
        }
    }
}

.aa-bg-overlay {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 5;
    }

    > .aa-inner {
        position: relative;
        z-index: 10;
    }
}

.aa-bg-video {
    position: relative;

    > .aa-inner {
        position: relative;
        z-index: 10;
    }
}

.aa-bg-video video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// IE11 responsive background video: https://jsfiddle.net/StartBootstrap/enajc82d/
.ie .aa-bg-video {
    overflow: hidden;
}

.ie .aa-bg-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    min-width: 100%;
    min-height: 100%;
    max-height: none;
    width: auto;
    height: auto;
    padding: 0;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
}

 /*
 * The container of a module
 */
.aa-module {
    display: flex;

    padding-left: $padding;
    padding-right: $padding;
}

/*
 * Sets the width of the module
 */
.aa-module-width--page,
.aa-module-width--full {
    width: 100%;
}

.aa-module-width--page {
    @include bp(lg) {
        max-width: $full-site-width;
        margin-left: auto;
        margin-right: auto;
    }
}

/*
 * Sets the height of the module
 */
.aa-module-height--small,
.aa-module-height--normal,
.aa-module-height--full {
    display: flex;
    align-items: center;
}
.aa-module-height--small {
    min-height: 400px;

    @media (max-height: 400px) {
        min-height: 100vh;
    }
}
.aa-module-height--normal {
    min-height: 800px;

    @media (max-height: 800px) {
        min-height: 100vh;
    }
}
.aa-module-height--full {
    min-height: 100vh;
}

/*
 * Sets the spacing on the module
 */
.aa-module-spacing--small {
    @extend %eiffel-top;
    @extend %eiffel-bottom;
}
.aa-module-spacing--medium {
    @extend %empire-top;
    @extend %empire-bottom;
}
.aa-module-spacing--large {
    @extend %burj-top;
    @extend %burj-bottom;
}

.aa-module-spacing-bottom--small {
    @extend %eiffel-bottom;
}
.aa-module-spacing-bottom--medium {
    @extend %empire-bottom;
}
.aa-module-spacing-bottom--large {
    @extend %burj-bottom;
}

.aa-module-spacing-top--small {
    @extend %eiffel-top;
}
.aa-module-spacing-top--medium {
    @extend %empire-top;
}
.aa-module-spacing-top--large {
    @extend %burj-top;
}

/*
 * The container of a modules content
 */
.aa-content {
    width: 100%;
}

/*
 * Controls the position of the content within the module
 */
.aa-content-position--left {
    justify-content: flex-start;
}
.aa-content-position--center {
    justify-content: center;
}

/*
 * Controls the alignment of the content within the module
 */
.aa-content-alignment--left {
    text-align: left;
}
.aa-content-alignment--center {
    text-align: center;
}

.aa-inner {
    display: flex;
    width: 100%;

    @include bp(lg) {
        max-width: $full-site-width;
        margin-left: auto;
        margin-right: auto;
    }
}

/*
 * Sets the width of the content in a module
 */
.aa-content-width--narrow,
.aa-content-width--normal,
.aa-content-width--wide {
    width: 100%;
}
.aa-content-width--narrow {
    @include bp(md) {
        max-width: calc(#{$full-site-width} / 12 * 4);
    }
}
.aa-content-width--normal {
    @include bp(md) {
        max-width: calc(#{$full-site-width} / 12 * 6);
    }
}
.aa-content-width--wide {
    @include bp(lg) {
        max-width: calc(#{$full-site-width} / 12 * 8);
    }
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

/*
 * Bootstrap 4 hiding classes
 */
.hidden-xs-up {
    display: none !important;
}

@media (max-width: 543px) {
    .hidden-xs-down {
        display: none !important;
    }
}

@include bp(md) {
    .hidden-sm-up {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .hidden-sm-down {
        display: none !important;
    }
}

@include bp(md) {
    .hidden-md-up {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .hidden-md-down {
        display: none !important;
    }
}

@include bp(lg) {
    .hidden-lg-up {
        display: none !important;
    }
}

@media (max-width: 1199px) {
    .hidden-lg-down {
        display: none !important;
    }
}

@include bp(xl) {
    .hidden-xl-up {
        display: none !important;
    }
}

.hidden-xl-down {
    display: none !important;
}
