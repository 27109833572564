/* -------------------------------- *\
    Forms
\* -------------------------------- */

// HubSpot default form title
.form-title {
    margin: 0;
    padding-bottom: 30px; // Padding based spacing to account for form title background-color from theme settings

    color: $form-title-text-color;

    background-color: $form-title-background-color;

    &:empty {
        display: none;
    }
}

.hs-form {
    border: 1px solid $form-border-color;

    font-size: 16px; // Per default, most elements within the form inherit this font size.
    line-height: 22px; // Per default, most elements within the form inherit this line height.

    text-align: left; // Always align form fields to the left, independent on module alignment.

    background-color: $form-background-color;
}

// Reset default HS form styles inside our modules and templates
.aa-module,
.aa-landing-page {
    .form-title {
        padding: 0;

        color: inherit;

        background-color: transparent;
    }

    .hs-form {
        padding: 0;
        border: 0;

        background-color: transparent;
    }
}

/*
 * Layout & containers
 */

// Field container
.hs-form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @include bp(md) {
        margin-bottom: 25px;
    }
}

// Specific field type containers
.hs-fieldtype-text {}
.hs-fieldtype-select {}
.hs-fieldtype-booleancheckbox {}
.hs-fieldtype-checkbox {}
.hs-fieldtype-radio {}
.hs-fieldtype-date {}
.hs-fieldtype-file {}
.hs-fieldtype-textarea {}


/*
 * Input fields
 */

// All input fields
.hs-input {
    width: 100%;
    height: 60px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: $form-input-x-padding;
    padding-right: $form-input-x-padding;
    border-style: solid;
    border-width: $form-fields-border-width;
    border-color: $form-fields-border-color;

    color: inherit;
    line-height: 1;
    text-decoration: none;

    background-color: $form-fields-background-color;

    cursor: pointer;
    box-shadow: none;

    @include bp(lg) {
        padding-left: $form-input-x-padding-lg;
        padding-right: $form-input-x-padding-lg;
    }

    &:focus {
        outline: none;
        border-color: $form-fields-border-color-focus;
    }

    &::placeholder {
        color: #696969;
        opacity: 0.6;
    }
}

// Checbox/radio inputs
.hs-input[type="checkbox"],
.hs-input[type="radio"] {
    width: auto;
    height: auto;
    margin-right: 8px;
    border: 0;

    background-color: transparent;
}

// Checbox/radio input labels
.hs-form-field .inputs-list label span {
    user-select: none;
}

// Single checkbox inputs
.hs-fieldtype-booleancheckbox {
    .hs-input {}
}

// Multiple checkboxes/radios
.hs-form-field .multi-container {
    li {
        margin: 0; // Reset

        &:not(:last-of-type) {
            margin-bottom: 5px;
        }
    }
}

// Select (dropdown) inputs
.hs-fieldtype-select .input {
    position: relative; // Make it possible to align the icon
}

.hs-fieldtype-select .input svg {
    position: absolute;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(100% - (#{$form-input-x-padding} * 2));
    transform: translateX(-50%);

    pointer-events: none;

    @include bp(lg) {
        left: calc(100% - (#{$form-input-x-padding-lg} * 2));
    }
}
.hs-fieldtype-select select.hs-input svg path {
    fill: $black;
}

.hs-fieldtype-select select.hs-input:hover ~ svg path {
    fill: $secondary;
}

select.hs-input {
    appearance: none;

    option { // Dropdown items
        color: $black;

        padding: 0 10px;

        background-color: $white;
    }
}

.hs-fieldtype-textarea textarea.hs-input {
    width: 100%;
    height: 100px;
    padding: $form-input-x-padding;

    background-color: $form-fields-background-color;
    resize: vertical;

    @include bp(lg) {
        padding: $form-input-x-padding-lg;
    }
}

// File inputs
.hs-input[type="file"] {
    height: auto;
    padding: 0;

    background-color: transparent;
    border-color: transparent;
}

// Date inputs
.hs-fieldtype-date .hs-input {
    width: auto !important;
    padding-right: $form-input-x-padding * 2 + 24px;

    background-image: svg-url('<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 20H16V16H20V20ZM14 10H10V14H14V10ZM20 10H16V14H20V10ZM8 16H4V20H8V16ZM14 16H10V20H14V16ZM8 10H4V14H8V10ZM24 2V24H0V2H3V3C3 4.103 3.897 5 5 5C6.103 5 7 4.103 7 3V2H17V3C17 4.103 17.897 5 19 5C20.103 5 21 4.103 21 3V2H24ZM22 8H2V22H22V8ZM20 1C20 0.448 19.553 0 19 0C18.447 0 18 0.448 18 1V3C18 3.552 18.447 4 19 4C19.553 4 20 3.552 20 3V1ZM6 3C6 3.552 5.553 4 5 4C4.447 4 4 3.552 4 3V1C4 0.448 4.447 0 5 0C5.553 0 6 0.448 6 1V3Z" fill="#000"/></svg>');
    background-repeat: no-repeat;
    background-position: calc(100% - #{$form-input-x-padding}) center;
    background-size: 18px auto;

    @include bp(lg) {
        padding-right: $form-input-x-padding-lg * 2 + 24px;

        background-position: calc(100% -#{$form-input-x-padding-lg}) center;
    }

    &::-webkit-calendar-picker-indicator{
        display: none;  // Hide native calendar icon in Chrome
    }
}

// Date picker dropdown
.hs-fieldtype-date .hs-datepicker {
    .fn-date-picker .pika-button {
        box-shadow: none !important; // !important declaration to override HS styling.
        border-radius: 0 !important; // !important declaration to override HS styling.

        &:hover {
            background-color: $primary !important; // !important declaration to override HS styling
            opacity: 0.7 !important;
        }
    }

    .fn-date-picker .is-selected .pika-button {
        background-color: $complementary;
        color: $dark;
    }

    .fn-date-picker .is-today .pika-button {
        color: $dark;
    }
}

/*
 * Submit button
 */
.hs-submit {
    margin-top: 20px;

    text-align: center;
}

.hs-form .hs-button {
    @extend .aa-button;
    @extend .aa-button--form;

    min-width: 200px;
}

/*
 * Text and other form elements
 */
.hs-form ul,
.hs-form ol {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        list-style: none;
    }
}

// Form field main label
.hs-form-field > label  {
    font-weight: $weight-400;

    span {
        display: inline-block;
        margin-bottom: 5px;

        &:empty,
        &:empty + span {
            display: none;
        }
    }
}

// Form field help text
.hs-form legend {
    order: 1; // Positions it below the input field
    display: block;
    margin-top: 5px;
    margin-left: $form-input-x-padding;

    font-size: 0.8em;
    line-height: 1.2;

    opacity: 0.8;

    @include bp(lg) {
        margin-top: 10px;
        margin-left: $form-input-x-padding-lg;
    }
}

// Rich text areas
.hs-form .hs-richtext {
    p {
        font-size: inherit;
        line-height: inherit;

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }

    + .hs-form-field {
        margin-top: 10px;
    }
}

// Error messages
.hs-input.error {
    border-color: $form-error-color;
}

ul.hs-error-msgs {
    order: 10; // Position it last in the field container
    margin-top: 10px;
    margin-left: $form-input-x-padding;

    @include bp(lg) {
        margin-left: $form-input-x-padding-lg;
    }

    li {
        list-style: none;
    }

    label {
        color: $form-error-color;
        font-size: 0.8em;
        line-height: 1.2;
        font-style: italic;

        &:before {
            content: '!';
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 1.2rem;
            height: 1.2rem;
            border: 1px solid $form-error-color;
            border-radius: 50%;
            margin-right: 5px;

            font-style: normal;
            font-weight: $weight-700;
        }
    }
}

// The final error message just above the submit button (shows if you try to submit the form but there's an error)
.hs_error_rollup {
    margin-top: 15px;
    padding: 15px;
    border: 1px solid $form-error-color;

    .hs-error-msgs {
        margin: 0;
    }
}

// Form title that renders if the "form_title" parameter is set to true
.hs-form .form-title {
    @extend .h3;
}


/*
 * Custom GDPR fields
 */
.hs_gdpr_consent_marketing,
.hs_gdpr_consent_data {
    .hs-form-booleancheckbox label {
        font-weight: $weight-700;
    }
}


/*
 * Reset/override unwanted HubSpot styling.
 * Note: We use a lot of CSS specificity here on purpose in order to beat HubSpot's specificity.
 */
form.hs-form fieldset {
    max-width: none;
}

form.hs-form fieldset.form-columns-1,
form.hs-form fieldset.form-columns-2,
form.hs-form fieldset.form-columns-3 { // Override HubSpot styling
    .hs-form-field {
        width: 100%;
    }

    .input {
        margin-right: 0;
    }
}

form.hs-form fieldset.form-columns-1 { // Override HubSpot styling
    .hs-input {
        width: 100%;
    }

    input[type="checkbox"].hs-input,
    input[type="radio"].hs-input {
        width: auto;
    }
}

form.hs-form fieldset.form-columns-2 { // Override HubSpot styling
    .hs-form-field {
        @include bp(md) {
            width: calc(50% - #{$form-field-gutter} / 2);
        }

        &:not(:first-of-type) {
            @include bp(md) {
                margin-left: $form-field-gutter;
            }
        }
    }
}

form.hs-form fieldset.form-columns-3 {
    .hs-form-field {
        @include bp(md) {
            width: calc(100% / 3 - 2 * #{$form-field-gutter} / 3);
        }

        &:not(:first-of-type) {
            @include bp(md) {
                margin-left: $form-field-gutter;
            }
        }
    }
}

@media (max-width: 400px), (max-device-width: 480px) and (min-device-width: 320px) { // Override HubSpot styling
    form.hs-form fieldset.form-columns-2,
    form.hs-form fieldset.form-columns-3 {
        .hs-input {
            width: 100% !important;
        }

        input[type="checkbox"],
        input[type="radio"] {
            width: auto !important;
        }
    }
}

/* Custom checkbox */
.aa-content-color--dark ul.inputs-list.multi-container li.hs-form-checkbox,
.aa-content-color--dark ul.inputs-list li.hs-form-booleancheckbox,
ul.inputs-list.multi-container li.hs-form-checkbox,
ul.inputs-list li.hs-form-booleancheckbox {

    margin: 16px 0;

    label {
        position: relative;
        display: flex;
    }

    label span:not(.hs-form-required) {
        display: inline-block;
        margin-left: 30px;
        margin-right: 16px;
    }

    // Resize and hide the original input
    label input {
        height: 20px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 20px;
    }

    // Add new input
    label span:not(.hs-form-required)::before {
        border: 1px solid;
        content: "";
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;

        border: 1px solid $primary;
        border-radius: 2px;
        background-size: 100% 1px;
        background-position: 0 0, 0 100%;
        background-repeat: no-repeat;

    }

    // Checked item
    label span:not(.hs-form-required)::after {
        content: "";
        height: 14px;
        width: 14px;
        border: 1px solid $primary;
        border-radius: 2px;
        left: 3px;
        opacity: 0;
        position: absolute;
        background: $primary;
        top: 3px;
        transition: opacity 0.2s ease-in-out;
        cursor: pointer;
    }

    // Show checked style
    label input:checked + span:not(.hs-form-required)::after {
        opacity: 1;
    }

    // Focused state
    label input:focus + span:not(.hs-form-required)::after {
        outline: 3px solid transparent; /* For Windows high contrast mode. */
    }

}

.aa-bg-primary ul.inputs-list.multi-container li.hs-form-checkbox,
.aa-bg-primary ul.inputs-list li.hs-form-booleancheckbox,
.aa-bg-complementary ul.inputs-list.multi-container li.hs-form-checkbox,
.aa-bg-complementary ul.inputs-list li.hs-form-booleancheckbox,
.aa-bg-dark ul.inputs-list.multi-container li.hs-form-checkbox,
.aa-bg-dark ul.inputs-list li.hs-form-booleancheckbox {
    label span:not(.hs-form-required)::before {
        border: 1px solid $white;
    }
    // Checked item
    label span:not(.hs-form-required)::after {
        background: $white;
        border: 1px solid $white;
    }
}


/* Custom radio button */
.aa-bg-image .aa-panels__form ul.inputs-list.multi-container li.hs-form-radio,
ul.inputs-list.multi-container li.hs-form-radio {
    margin: 16px 0;

    label {
        position: relative;
    }

    label span {
        display: inline-block;
        margin-left: 30px;
        margin-right: 16px;
    }

    // Resize and hide the original input
    label input {
        height: 20px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 20px;
    }

    // Add new input
    label span::before {
        border: 1px solid;
        content: "";
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
        border-radius: 50%;
    }

    // Checked item
    label span::after {
        content: "";
        height: 14px;
        width: 14px;
        left: 3px;
        position: absolute;
        background-color: $white;
        top: 3px;
        border-radius: 50%;
        -webkit-transition: background-color .2s ease-in-out;
        transition: background-color .2s ease-in-out;
        cursor: pointer;
        border: 1px solid $white;// Inset the selection from gradient border
    }

    // Show checked style
    label input:checked + span::after {
        background-color: $primary;
        border: 1px solid $primary;
    }

    // Focused state
    label input:focus + span::after {
        outline: 3px solid transparent; /* For Windows high contrast mode. */
    }

}

.aa-bg-image ul.inputs-list.multi-container li.hs-form-radio,
.aa-bg-primary ul.inputs-list.multi-container li.hs-form-radio,
.aa-bg-complementary ul.inputs-list.multi-container li.hs-form-radio,
.aa-bg-dark ul.inputs-list.multi-container li.hs-form-radio  {
    // Checked item
    label span::after {
        background-color: transparent;
        border: 1px solid transparent; // Inset the selection from gradient border
    }

    // Show checked style
    label input:checked + span::after {
        background-color: $white;
        border: 1px solid $white;
    }
}

.aa-bg-complementary,
.aa-bg-primary,
.aa-bg-dark {

    .hs-input {
        color: $dark;

        &:focus {
            background: $white;
            border-color: $white;
        }
    }

    ul.hs-error-msgs {

        label {
            color: $white;

            &:before {
                border: 1px solid $white;
            }
        }
    }
}