/*-----------------------------------*\
  AA - Rich Text
\*-----------------------------------*/

.aa-text {
    position: relative;
}

.aa-text__header,
.aa-text__body {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

//modifier columns version
.aa-text--columns {
    .aa-text__body {

        @include bp(md) {
            display: flex;
        }
    }
    .aa-text-column {
        &:first-of-type {
            margin-right: 30px;
            margin-bottom: 30px;

            @include bp(md) {
                margin-bottom: 0px;
            }
        }
    }
}