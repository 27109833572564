/*-----------------------------------*\
  AA - Hero
\*-----------------------------------*/

.aa-hero {
    position: relative;
    justify-content: center;

    .aa-inner {
        @extend %empire-bottom;
    }

    .aa-scroll-indicator {
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;
        z-index: 10;
    }
}

.aa-hero__header,
.aa-hero__body {
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }
}

.aa-hero--mobile-version.aa-content-color--light {
    color: $black;

    @include bp(lg) {
        color: $white;
    }
}

.aa-hero--mobile-version {
    flex-direction: column;

    .aa-hero--image {
        margin-bottom: 20px;
        width: 100%;

        @include bp(lg) {
            margin-bottom: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.aa-hero--mobile-version::before {
    display: none;

    @include bp(lg) {
        display: block;
    }
}