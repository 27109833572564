/*-----------------------------------*\
    Quote
\*-----------------------------------*/

.aa-quote {
    margin-bottom: 65px; // for offset

    .aa-inner {
        @include bp(lg) {
            max-width: calc(#{$full-site-width} / 12 * 10);
            margin-left: auto;
            margin-right: auto;
        }
    }

    .aa-content {
        @include bp(md) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}


.aa-quote__header {
    max-width: 530px;
    position: relative;
    width: 100%;

    @include bp(md) {
        margin-bottom: -65px;
        min-width: 400px;
    }

    .aa-quote__image {
        width: 100%;
        height: 100%;
    }
}

.aa-quote__body {
    max-width: 480px;
    padding-bottom: 30px;
    padding-top: 30px;

    @include bp(md) {
        padding-bottom: 100px;
        padding-top: 100px;
        margin-left: 30px;
    }

    blockquote {
        font-weight: $weight-400;
        font-size: 16px;
        line-height: 22px;
        margin-top: 0;
        margin-bottom: 40px;

        @include bp(md) {
            margin-bottom: 70px;
        }
    }
}

.aa-quote__icon {
    margin-bottom: 40px;

    @include bp(md) {
        margin-bottom: 80px;
    }

    svg {
        fill: $primary;
    }
}

.aa-quote__title {
    @extend h2;

    display: block;
    margin-bottom: 30px;

    @include bp(md) {
        margin-bottom: 50px;
    }
}

.aa-quote__name {
    font-weight: $weight-400;
    font-size: 36px;
    line-height: 22px;
    font-family: 'Alex Brush', cursive;
}

.aa-quote__position {
    font-weight: $weight-400;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
}

.aa-bg-primary {
    .aa-quote__icon {
        svg {
            fill: $white;
        }
    }
    .aa-quote__position {
        color: $white;
    }
}