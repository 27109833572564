/*-----------------------------------*\
    Cards
\*-----------------------------------*/

.aa-cards {
    .aa-inner {
        max-width: calc(#{$full-site-width} / 12 * 10);
        margin-left: auto;
        margin-right: auto;
    }
}

.aa-cards__header {
    margin-bottom: 30px;

    @include bp(lg) {
        margin-bottom: 60px;
    }
}

.aa-cards__body {
    width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 20px;

	@include bp(lg) {
		grid-template-columns: repeat(3, 1fr);
        max-width: 100%;
		column-gap: 25px;
		row-gap: 25px;
	}
}

.aa-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $white;

    transition: all $speed $animation;

    &:hover {
        background-color: $white;
        box-shadow: 0 4px 32px 0 rgba($black, .1);

        text-decoration: none;
    }
}

.aa-card.aa-card-nolink {

    &:hover {
        background-color: none;
        box-shadow: none;
        text-decoration: none;
    }
}

.aa-card__header {
    img {
        width: 100%;
    }
}

.aa-card__body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    padding: 32px 16px;

    background-color: $white;

    transition: all $speed $animation;

    @include bp(xl) {
        padding: 32px;
    }
}

// Overlay content on image
.aa-cards--overlay-content {
    .aa-subheading {
        margin-bottom: 60px;

        @include bp(lg) {
            margin-bottom: 90px;
        }
    }

    .aa-card {
        text-decoration: none;

        &:hover {
            box-shadow: 0 4px 32px 0 rgba($black, .1);
            text-decoration: none;
        }
    }

    .aa-card__body {
        position: relative;
        z-index: 10;
        background-color: transparent;
    }
}

.aa-card.aa-bg-overlay--primary {
    &:before {
        background: linear-gradient(360deg, rgba($primary, 0.8) 32.86%, rgba($primary, 0.5) 100%);
    }

    &:hover {
        color: $white;
    }

    .aa-button {
        padding: 8px 24px,
    }

    .aa-button--2 {
        border: 1px solid $white;
        color: $white;

        &:hover {
            border: 1px solid $white;
            color: $primary;
            background-color: $white;
        }
    }
}

.aa-card.aa-bg-overlay--secondary {
    &:before {
        background: linear-gradient(360deg, rgba($secondary, 0.8) 32.86%, rgba($secondary, 0.5) 100%);
    }

    &:hover {
        color: $dark;
    }

    .aa-button {
        padding: 8px 24px,
    }
}

.aa-card.aa-bg-overlay--complementary {
    &:before {
        background: linear-gradient(360deg, rgba($complementary, 0.8) 32.86%, rgba($complementary, 0.5) 100%);
    }

    &:hover {
        color: $white;
    }

    .aa-button {
        padding: 8px 24px,
    }
}

.aa-card.aa-bg-overlay--dark {
    &:before {
        background: linear-gradient(360deg, rgba($dark, 0.8) 32.86%, rgba($dark, 0.5) 100%);
    }

    &:hover {
        color: $white;
    }

    .aa-button {
        padding: 8px 24px,
    }

    .aa-button--2 {
        border: 1px solid $white;
        color: $white;

        &:hover {
            border: 1px solid $white;
            color: $primary;
            background-color: $white;
        }
    }
}

.aa-card.aa-bg-overlay--light {
    &:before {
        background: linear-gradient(360deg, rgba($light, 0.8) 32.86%, rgba($light, 0.5) 100%);
    }

    &:hover {
        color: $dark;
    }

    .aa-button {
        padding: 8px 24px,
    }
}

.aa-card.aa-bg-overlay--white {
    &:before {
        background: linear-gradient(360deg, rgba($white, 0.8) 32.86%, rgba($white, 0.5) 100%);
    }
    color: $dark;

    &:hover {
        color: $dark;
    }

    .aa-button {
        padding: 8px 24px,
    }
}

// Modifiers for layout

.aa-cards--2 {
	.aa-cards__body {
		@include bp(lg) {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}

.aa-cards--3 {
	.aa-cards__body {
		@include bp(lg) {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

.aa-cards--4 {

    .aa-inner {
        max-width: $full-site-width;
    }

	.aa-cards__body {
		@include bp(lg) {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}
