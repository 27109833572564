/*-----------------------------------*\
    Blog Post
\*-----------------------------------*/

/************************

Blog Post Hero Section

************************/

.aa-blog-post-hero {
    position: relative;
    padding-left: $padding;
    padding-right: $padding;
    padding-top: 132px;

    text-align: center;

    @include bp(lg) {
        padding-top: 192px;
    }

    &:after {
        content:"";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
        background-blend-mode: luminosity, normal;
    }
}

.aa-blog-post-hero__inner {
    position: relative;
    z-index: 12;
    max-width: calc(#{$full-site-width} / 12 * 10);
    margin-left: auto;
    margin-right: auto;
}

.aa-blog-post-hero__header {
    max-width: calc(#{$full-site-width} / 12 * 8);
    margin-left: auto;
    margin-right: auto;

    h1 {
        font-weight: $weight-900;
    }
}

.aa-blog-post-hero__meta {
    @extend %eiffel-top;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include bp(lg) {
        flex-direction: row;
    }

    .aa-article-card__date {
        margin-bottom: 0;
        font-weight: $weight-400;
        font-size: 12px;
        line-height: 14px;
        color: $secondary;
    }

    .aa-blog-post-hero__author {
        display: flex;
        margin-bottom: 0;

        font-weight: $weight-400;
        font-size: 18px;
        line-height: 22px;
        color: $white;

        @include bp(lg) {
            margin-left: 35px;
        }
    }

    .aa-blog-author {
        color: $white;
        margin-left: 5px;
        text-decoration: none;

        &:hover {
            color: rgba($white, 0.8);
        }
    }
}

.aa-blog-post-hero__tags {
    a {
        color: $dark;
        text-decoration: none;
        font-weight: $weight-400;
        font-size: 18px;
        line-height: 18px;
    }
}

.aa-blog-post-hero__header {
    color: $white;

    .aa-subheading {
        font-weight: $weight-400;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #DFDFDF;
    }
}

.aa-blog-post-hero__body {
    padding-top: 45px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include bp(lg) {
        flex-direction: row;
        justify-content: space-between;
        padding-top: 150px;
    }

    .aa-blog-post-hero__tags {
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: $white;

        a {
            color: $white;
            margin-right: 35px;

            &:last-of-type {
                margin-right: 0;
            }

            &:hover {
                color: rgba($white, 0.8);
            }
        }
    }
}

.aa-blog-post-hero-social__inner {
    display: flex;
    margin-top: 30px;

    @include bp(lg) {
        margin-top: 0;
    }

    a {
        margin-right: 10px;

        &:last-of-type {
            margin-right: 0;
        }

        .icon {
            fill: $secondary;
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            .icon {
                fill: rgba($secondary, 0.8);
            }
        }
    }
}

/************************

Blog Post Content

************************/

.aa-blog-post-content {
    @extend %burj-top;
    @extend %empire-bottom;

    padding-left: $padding;
    padding-right: $padding;
}

.aa-blog-post-content__inner {
    width: 100%;

    @include bp(lg) {
        max-width: calc(#{$full-site-width} / 12 * 6);
        margin-left: auto;
        margin-right: auto;
    }

    span.cta-wrapper {
        display: block;
    }

    * + img {
        margin-top: 30px;

        @include bp(md) {
            margin-top: 50px;
        }
    }

    img {
        margin-bottom: 30px;

        @include bp(md){
            margin-bottom: 50px;
        }
    }

    ul,
    ol {
        margin-top: 0;
        margin-bottom: 30px;
    }

    p > a {
        display: inline;
    }

    ul,
    ol {
        margin-bottom: 40px;
    }
}