/*-----------------------------------*\
    Header
\*-----------------------------------*/

.aa-site-header {
    position: relative;
    width: 100%;
    height: 92px;
    background-color: $white;

    padding-left: $padding;
    padding-right: $padding;

    z-index: 20;
}

.aa-site-header.aa-site-header--scrolled {
    position: fixed;
    background-color: rgba($white,0.8);
    box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.07);

    @include bp(lg) {
        margin-top: -35px;
    }
}

.aa-site-header--secondary.aa-site-header--scrolled {
    height: 0;
}

.aa-site-header--landing-page {
    position: absolute;

    background-color: transparent;
}

.aa-site-header--landing-page-plus {
    background-color: $light;

    .aa-site-header__inner {
        justify-content: center;

    }
}

.aa-site-header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: inherit;

    @include bp(lg) {
        max-width: $full-site-width;
        margin-left: auto;
        margin-right: auto;
    }
}
.aa-branding {
    width: 100%;
    max-width: 112px;
}

.aa-site-header--secondary {
    @include bp(lg) {
        background-color: #F3F3F3;
        height: 35px;
    }
}

.aa-site-header--secondary__inner {
    padding-bottom: 200px;

    @include bp(lg) {
        display: flex;
        justify-content: space-between;
        max-width: $full-site-width;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 0;
    }
}

.aa-site-header--secondary__header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;

    @include bp(lg) {
        background-color: $primary;
        height: auto;
    }

    a {
        position: relative;
        width: 17px;
        height: 17px;
        padding-right: 20px;
        padding-left: 20px;
        border-right: 0.4px solid #612029;

        &:last-of-type {
            border-right: none;
        }
    }
}

.aa-site-header--secondary__some {
    a svg {
        fill: $primary;

        @include bp(lg) {
            fill: $white;
        }
    }

    a svg path {
        fill: $primary;

        @include bp(lg) {
            fill: $white;
        }
    }
}

.aa-site-header--secondary__body {
    text-align: center;
    margin-top: 20px;

    @include bp(lg) {
        margin-top: 0;
        text-align: initial;
        display: flex;
        align-items: center;
    }
}

.aa-site-header--secondary__tel,
.aa-site-header--secondary__mail {
    padding-right: 20px;
    font-weight: $weight-400;
    font-size: 14px;
    line-height: 22px;

    a {
        text-decoration: none;
        font-weight: $weight-400;
        font-size: 14px;
        line-height: 22px;
    }

    svg {
        margin-right: 10px;
    }
}

.aa-site-header--secondary__tel {
    @include bp(lg) {
        border-right: 0.4px solid #612029;
    }
}

.aa-site-header--secondary__mail {
    @include bp(lg) {
        padding-left: 20px;
    }

    a {
        text-decoration: none;
        font-weight: $weight-400;
        font-size: 14px;
        line-height: 22px;
    }
}

.aa-site-header--secondary__some {
    a {
        text-decoration: none;

        &:focus,
        &:active,
        &:hover {
            text-decoration: none;
        }
    }
}