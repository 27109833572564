/*-----------------------------------*\
    AA - Employees
\*-----------------------------------*/

.aa-employees {
	.aa-inner {
		max-width: $full-site-width;
		width: 100%;
		flex-direction: column;
	}
}

.aa-employees__header {
    max-width: $full-site-width;

	@include bp(sm) {
		margin-left: auto;
		margin-bottom: 20px;
	}

    .aa-filter {
        cursor: pointer;
        appearance: none;
		height: 60px;
		padding: 18px 30px;
		border: none;
		border: 1px solid $black;
		border-radius: 0;
		color: #696969;

		font-family: $body-font;
		font-weight: $weight-400;
		font-size: 18px;
		line-height: 26px;

		background-color: transparent;
		background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.28571 0L8 5.71429L13.7143 0L16 1.14286L8 9.14286L0 1.14286L2.28571 0Z' fill='black'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 10px auto;
        background-position: calc(100% - 30px) 50%;

        @include bp(sm) {
			margin-top: 0;
			min-width: 475px;
		}

        &:focus, &:active {
            outline: 0;
		}
	}
}


.aa-employees__body {
	display: grid;
	grid-template-columns: 1fr;
	max-width: $full-site-width;
	row-gap: 6px;

	@include bp(sm) {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 6px;
	}

	@include bp(lg) {
		grid-template-columns: repeat(3, 1fr);
	}
}

.aa-employee  {
	position: relative;
	min-height: 620px;
	border-bottom: 7px solid $primary;
}

.aa-employee__body {
	position: absolute;
	bottom: -7px; //overlap border
	left: 0;
	z-index: 6;
	max-width: 330px;
	width: 100%;
	background-color: $white;
	padding: 35px;

	p {
		margin-bottom: 0;
	}

	.aa-employee__department {
		text-transform: uppercase;
		margin-bottom: 30px;
	}

	.aa-employee__name {
		margin-top: 0;
		margin-bottom: 30px;
	}
}

// show and hide

.aa-employee.active {
	display: inline-block;
}

.aa-employee.hidden {
	display: none;
}