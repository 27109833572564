.aa-logo-wall {
	@extend %burj-bottom;

	position: relative;
	padding-top: 60px;

	@include bp(sm) {
		padding-top: 120px;
	}

	@include bp(lg) {
		padding-top: 240px;
	}

	&:after {
		content: "";
		z-index: 1;
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
	}

	&:before {
		content: "";
		position: absolute;
		z-index: 2;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(180deg, rgba(34, 34, 34, 0) -39.95%, #222222 100%)
	}

	.aa-inner {
        max-width: $max-site-width;
        margin-left: auto;
        margin-right: auto;
	}

	.aa-content {
		position: relative;
		z-index: 10;
		max-width: calc(#{$full-site-width} / 12 * 10);
		margin-left: auto;
        margin-right: auto;
	}
}

.aa-logo-wall__header {
	@extend %burj-bottom;

	text-align: center;
}

.aa-logo-wall__body {
    width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	row-gap: 40px;
	margin-bottom: 70px;

	@include bp(md) {
		grid-template-columns: repeat(4, 1fr);
        max-width: 100%;
		column-gap: 25px;
		row-gap: 120px;
	}

	@include bp(xl) {
		grid-template-columns: repeat(6, 1fr);
	}
}

.aa-logo-item {
	img {
		max-width: 100px;
		max-height: 44px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		filter: grayscale(100%);
		transition: all ease-in-out 0.2s;
		cursor: pointer;

		@include bp(sm) {
			max-width: 160px;
		}
	}

	&:hover {

		img {
			filter: grayscale(0);
			transform: scale(1.1);
		}
	}
}

.aa-logo-wall__footer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.aa-button {
		margin-top: 70px;
		margin-left: auto;
		margin-right: auto;
	}
}