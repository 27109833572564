/*-----------------------------------*\
  Videos
\*-----------------------------------*/

.aa-video-container {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    background-size: cover;
    background-repeat: no-repeat;

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        height: auto;
        background-size: cover;
        transform: none;
        margin-left: 0;
        object-fit: cover;
    }
}

.aa-video-container--youtube {
    padding-bottom: 0;
}

.aa-video-thumbnail {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 1;
	opacity: 1;
	transition: opacity $speed $animation;

	cursor: pointer;

	&:before {
		content: '';
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
	}

	&.is-hidden {
        opacity: 0;
        visibility: hidden;
    }

    + video {
        visibility: hidden;
    }

    &.is-hidden + video {
        visibility: visible;
    }

	.aa-play {
		pointer-events: none;
	}
}

// Video play button

.aa-play {
    // Browser reset of <button>
    appearance: none;
    border: 0;
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;


    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 100;

    transition: all $speed ease;

    @include bp(md) {
        height: 80px;
        width: 80px;
    }

    &:before {
        position: relative;
        content: '';
        display: block;
        width: 0;
        height: 0;
        margin-top: 2px;
        margin-left: 6px;
        border-style: solid;
        border-width: 17.5px 0 17.5px 23px;
        border-color: transparent transparent transparent $white;

        z-index: 100;

        @include bp(md) {
            border-width: 35px 0 35px 46px;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

// YouTube videos
.aa-youtube {
    background-color: $black;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: .3;
    }
}
.aa-youtube img {
    width: 100%;
    height: auto;
    top: -16.84%;
    left: 0;
}


.aa-youtube img,
.aa-youtube iframe {
    position: absolute;

}

.aa-youtube iframe {
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}