/*-----------------------------------*\
    Language menu
\*-----------------------------------*/

.aa-site-header--secondary__language {
    max-width: 170px;
    height: 35px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;

    @include bp(lg) {
        height: 100%;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }
}

.aa-language-menu {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 22;
    margin-right: 20px;
    height: 100%;
    min-width: 170px;
    padding-left: 20px;

    background: linear-gradient(89.4deg, #84414A 0.4%, #84414A 99.36%);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $white;

    cursor: pointer;

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        width: 10px;
        height: 6px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_i_3259_2209)'%3E%3Cpath d='M8.08729 -0.000116983L4.58203 3.4975L1.07678 -0.000116676L-2.00287e-07 1.07666L4.58203 5.65869L9.16406 1.07666L8.08729 -0.000116983Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_i_3259_2209' x='0' y='0' width='9.16406' height='6.65869' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='0.5'/%3E%3CfeComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='shape' result='effect1_innerShadow_3259_2209'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 2;
    }
}

.aa-language-menu__currentlang {
    display: flex;
    align-items: center;

    span {
        margin-left: 15px;
        transition: all $speed $animation;
    }

    li {
        display: none;
    }

    li.is-active {
        display: block;

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $white;
        }
    }
}

ul.aa-language-menu__dropdown {
    display: none;
    width: 100%;
    position: absolute;
    z-index: 22;
    bottom: calc(100%);
    left: 0;

    color: $white;
    background: linear-gradient(89.4deg, #84414A 0.4%, #84414A 99.36%);
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.25);
    padding-left: 0;

    @include bp(xl) {
        top: calc(100% - 1px);
        bottom: auto;
    }

    li {
        padding: 10px 20px;
        list-style: none;
    }


    li a {
        display: flex;
        align-items: center;
        color: $white;
        text-decoration: none;

        img {
            margin-right: 15px;
            border-radius: 50%;
        }
    }

    svg {
        fill: $white;
        margin-left: 20px;
    }
}

.aa-language-menu:hover .aa-language-menu__dropdown {
    display: block;
}


.aa-navigation--hamburger .aa-language-menu {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-right: 0;
    height: 50px;

    ul li a {
        font-size: 18px;
        line-height: 26px;
        font-weight: $weight-500;

        @include bp(sm) {
            font-size: 20px;
            line-height: 28px;
        }

        &:before {
            display: none;
        }
    }

    .aa-language-menu__currentlang {
        user-select: none;

        svg {
            width: 20px;
            height: 20px;

            @include bp(sm) {
                width: 25px;
                height: 25px;
            }

            path {
                fill: $white;
            }
        }

        > span {
            position: relative;
            display: flex;
            align-items: center;

            &:after {
                content: "";
                height: 12px;
                width: 12px;
                margin-left: 8px;

                background: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="640" height="640" viewBox="0 0 640 640"><title></title><g id="icomoon-ignore"></g><path fill="#fff" d="M557.376 195.488c8.704-8.576 22.688-8.576 31.328 0s8.672 22.432 0 31.008l-253.056 250.56c-8.64 8.576-22.624 8.576-31.328 0l-253.056-250.56c-8.64-8.576-8.64-22.432 0-31.008 8.672-8.576 22.688-8.576 31.328 0l237.408 228.512 237.376-228.512z"></path></svg>');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }

    &:hover .aa-language-menu__dropdown {
        @include bp(xl) {
            margin-top: 15px;
        }
    }
}