/*-----------------------------------*\
    Footer
\*-----------------------------------*/

.aa-site-footer {
    position: relative;
    width: 100%;
    border-bottom: 12px solid $primary;
}

.aa-site-footer__inner {
    @extend %burj-bottom;

    position: relative;
    z-index: 10;
    width: 100%;
    padding-top: 90px;

    @include bp(lg) {
        padding-top: 140px;
    }
}

.aa-site-footer__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;

    @include bp(lg) {
        max-width: calc(#{$full-site-width} / 12 * 2);
        margin-left: auto;
        margin-right: auto;
    }

    .aa-branding {
        max-width: 175px;
        margin-bottom: 45px;

        img {
            width: 100%;
        }
    }

    p strong {
        margin-bottom: 20px;
        display: block;
    }

    p {
        color: $white;
        font-size: 16px;
        line-height: 22px;
        margin-top: 10px;
        margin-bottom: 0;
    }

    a {
        text-decoration: none;
        color: $white;
        font-size: 16px;
        line-height: 22px;
        margin-top: 10px;
        margin-bottom: 20px;

        &:hover {
            color: rgba($white, 0.8);
        }
    }
}