/*-----------------------------------*\
    Columns
\*-----------------------------------*/

.aa-columns .aa-inner {
    flex-direction: column;
}

.aa-columns__body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aa-column {
    @include bp(md) {
        width: calc(100% / 2);
        padding-left: 0;
        padding-right: 0;
    }
}

.aa-column__body {
    margin-bottom: 20px;

    @include bp(lg) {
        margin-bottom: 40px;
    }
}

.aa-column--text {
    @extend %empire-bottom;
    @extend %empire-top;

    padding-left: $padding;
    padding-right: $padding;

    @include bp(lg) {
        max-width: calc(#{$full-site-width} / 12 * 4);
        margin-left: auto;
        margin-right: auto;
    }

    @include bp(xl) {
        padding-left: 0;
        padding-right: 0;
    }
}

.aa-column--image {
    align-self: stretch;
    min-height: 500px;
}

.aa-columns__body + .aa-columns__body {
    margin-top: 60px;
}

.aa-columns__body:nth-of-type(odd) {
    @include bp(md) {
        flex-direction: row-reverse;
    }
}
.aa-columns__body:nth-of-type(even) {
    @include bp(md) {
        flex-direction: row;
    }
}

/*
* Modifiers
*/

.aa-columns--image-center {
    .aa-columns__body {
        row-gap: 40px;
    }

    .aa-columns__body + .aa-columns__body {
        margin-top: 80px;

        @include bp(md) {
            margin-top: 60px;
        }
    }


    .aa-column--image {
        align-self: center;
        min-height: 0;

        img {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .aa-column--text {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.aa-columns--no-spacing {
    .aa-columns__body + .aa-columns__body {
        margin-top: 0;
    }
}

.aa-columns--reversed {
    .aa-columns__body:nth-of-type(odd) {
        @include bp(md) {
            flex-direction: row;
        }
    }

    .aa-columns__body:nth-of-type(even) {
        @include bp(md) {
            flex-direction: row-reverse;
        }
    }
}