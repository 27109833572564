/*-----------------------------------*\
    Pagination
\*-----------------------------------*/

.aa-pagination {
    @extend %burj-bottom;

    display: flex;
    padding-left: $padding;
    padding-right: $padding;
    justify-content: center;

     @include bp(lg) {
        max-width: calc(#{$full-site-width} / 12 * 6);
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    ul {
        display: flex;
        flex-flow: row wrap;
        padding: 0;
        margin: 0;
    }

    li {
        list-style-type: none;

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }

        @include bp(lg) {
            &:not(:last-of-type) {
                margin-bottom: 0;
            }
        }
    }

    li a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;

        font-size: 24px;
        text-decoration: none;

        background-color: transparent;
        color: $primary;

        &.aa-pagination--active {
            background-color: $primary;
            color: $white;
        }

        &:hover {
            background-color: rgba($primary, .20);
            color: $primary;
        }
    }
}