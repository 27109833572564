/*--------------------------------------------------------------------------------------------------------------*\
    Theme custom variables

    To override theme default variables, uncomment the variables below.
    (If you need some of the default variables, uncomment only the ones that should be overridden)

    Tip: You can toggle/untoggle comments rapidly with the "Toggle Line Comment" shortcut
    (editor.action.commentLine).
\*--------------------------------------------------------------------------------------------------------------*/

// Standard colors
$black: #000000;
$white: #ffffff;

// Brand colors
$primary: #84414A;
$secondary: #DDDDDD;
$complementary: #4D4D4F;
$dark: #222222;
$light: #F8F8F8;

// Brand colors - RGB values. You should not need to change these.
$primary-rgb: rgba($primary,1);
$secondary-rgb: rgba($secondary,1);
$complementary-rgb: rgba($complementary,1);
$dark-rgb: rgba($dark,1);
$light-rgb: rgba($light,1);

// Color map for utility classes
$main-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'complementary': $complementary,
    'light': $light,
    'dark': $dark,
    'white': $white,
    'black': $black
);

// Text colors
$body-color: $black;
$heading-color: inherit;
$text-dark: $black;
$text-light: $white;

// Accent color
$accent: $secondary;

// Link colors & decoration
$link-color: $primary;
$link-color-hover: lighten($primary,15%);
$link-text-decoration: underline;

// Text/content colors on brand colored backgrounds
$primary-bg-text: $text-light; // Text color on Primary colored backgrounds
$primary-bg-link: $complementary; // Link color on Primary colored backgrounds
$primary-bg-link-hover: $complementary; // Link hover color on Primary colored backgrounds
$primary-bg-accent: inherit; // Accent color on Primary colored backgrounds

$secondary-bg-text: $text-light; // Text color on Secondary colored backgrounds
$secondary-bg-link: $link-color; // Link color on Secondary colored backgrounds
$secondary-bg-link-hover: $link-color-hover; // Link hover color on Secondary colored backgrounds
$secondary-bg-accent: inherit; // Accent color on Secondary colored backgrounds

$complementary-bg-text: $text-dark; // Text color on Complementary colored backgrounds
$complementary-bg-link: $link-color; // Link color on Complementary colored backgrounds
$complementary-bg-link-hover: $link-color-hover; // Link hover color on Complementary colored backgrounds
$complementary-bg-accent: inherit; // Accent color on Complementary colored backgrounds

$dark-bg-text: $text-light; // Text color on Dark colored backgrounds
$dark-bg-link: $complementary; // Link color on Dark colored backgrounds
$dark-bg-link-hover: $complementary; // Link hover color on Dark colored backgrounds
$dark-bg-accent: inherit; // Accent color on Dark colored backgrounds

$light-bg-text: $text-dark; // Text color on Light colored backgrounds
$light-bg-link: $link-color; // Link color on Light colored backgrounds
$light-bg-link-hover: $link-color-hover; // Link hover color on Light colored backgrounds
$light-bg-accent: inherit; // Accent color on Light colored backgrounds

// Typography
$body-font: 'myriad-pro',sans-serif;
$heading-font: $body-font;

$h1-font-family: $heading-font;
$h1-font-weight: $weight-700;
$h1-font-style: normal;
$h1-text-decoration: none;

$h2-font-family: $heading-font;
$h2-font-weight: $weight-600;
$h2-font-style: normal;
$h2-text-decoration: none;

$h3-font-family: $heading-font;
$h3-font-weight: $weight-400;
$h3-font-style: normal;
$h3-text-decoration: none;

$h4-font-family: $heading-font;
$h4-font-weight: $weight-600;
$h4-font-style: normal;
$h4-text-decoration: none;

$h5-font-family: $heading-font;
$h5-font-weight: $weight-600;
$h5-font-style: normal;
$h5-text-decoration: none;

$h6-font-family: $heading-font;
$h6-font-weight: $weight-600;
$h6-font-style: normal;
$h6-text-decoration: none;

// Buttons
$button-font-family: $body-font;
$button-font-style: normal;
$button-font-weight: $weight-600;

$buttons: (
    "1": (
        "color": $white,
        "background-color": $primary,
        "border-color": $primary,
        "border-width": 1px,
        "border-radius": 30px,
        "padding": 16px 34px,
        // Hover
        "hover": (
            "color": $white,
            "background-color": $complementary,
            "border-color": $complementary
        )
    ),
    "2": (
        "color": $primary,
        "background-color": transparent,
        "border-color": $primary,
        "border-width": 1px,
        "border-radius": 30px,
        "padding": 16px 34px,
        // Hover
        "hover": (
            "color": $complementary,
            "background-color": transparent,
            "border-color": $complementary
        )
    ),
    "3": (
        "color": $primary,
        "background-color": $white,
        "border-color": $white,
        "border-width": 1px,
        "border-radius": 30px,
        "padding": 16px 34px,
        // Hover
        "hover": (
            "color": $primary,
            "background-color": $white,
            "border-color": $white
        )
    ),
    "4": (
        "color": $white,
        "background-color": transparent,
        "border-color": $primary,
        "border-width": 1px,
        "border-radius": 30px,
        "padding": 16px 34px,
        // Hover
        "hover": (
            "color": $white,
            "background-color": $primary,
            "border-color": $primary
        )
    ),
    "form": (
        "color": $white,
        "background-color": $primary,
        "border-color": $primary,
        "border-width": 1px,
        "border-radius": 0,
        "padding": 16px 34px,
        // Hover
        "hover": (
            "color": $white,
            "background-color": $secondary,
            "border-color": $secondary
        )
    )
);

/*
*   Forms
*/
// Padding left/right on form fields. Also used to position help texts and error messages.
$form-input-x-padding: 10px;
$form-input-x-padding-lg: 15px; // Breakpoint "Large" and up

// Horizontal spacing between form fields in multi-column layouts.
$form-field-gutter: 15px;

// Colors
$form-fields-background-color: transparent;
$form-fields-border-width: 1px; // For example "1px" for border on all sides or "0 0 1px" for just the bottom border.
$form-fields-border-color: $black;
$form-fields-border-color-focus: $primary; // Border color when the input field is focused. Make sure it's accessible to people who use the keyboard to navigate the form.
$form-error-color: $secondary; // General error color used for error messages and indicators.

// Marketplace required settings. Usually not necessary to change in client projects.
$form-title-text-color: $body-color; // Text color for the built-in HubSpot form title
$form-title-background-color: transparent; // Background color for the built-in HubSpot form title
$form-background-color: transparent; // Background color for the entire form
$form-border-color: transparent; // Border color for the entire form