/*-----------------------------------*\
    AA - Offset Columns
\*-----------------------------------*/

.aa-offset-column {
	.aa-inner {
		position: relative;
		flex-direction: column;

		@include bp(lg) {
			flex-direction: row-reverse;
		}
	}
}

.aa-offset-column__body {
	position: relative;
	width: 100%;

	@include bp(lg) {
		display: flex;
		flex-direction: row-reverse;
	}
}

.aa-offset-column__image {
	max-width: calc(#{$full-site-width} / 12 * 10);
	width: 100%;
	margin-bottom: 20px;

	@include bp(lg) {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		margin-bottom: 0;
	}

	video {
		width: 100%;
		height: 100%;
	}
}

.aa-offset-column__text {
	position: relative;
	z-index: 6;
	max-width: calc(#{$full-site-width} / 12 * 6);
	width: 100%;
	background-color: $white;

	@include bp(lg) {
		margin-top: 240px;
		margin-left: auto;
		padding: 80px;
		padding-right: 0;
	}
}

.aa-offset-column__footer {
	position: absolute;
	bottom: 0;
	z-index: 6;

	@include bp(lg) {
		position: relative;
		align-self: flex-end;
	}

	.aa-video-button {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		margin-right: 15px;
		cursor: pointer;

		span {
			margin-left: 10px;
			color: $white;
			text-transform: uppercase;
		}
	}
}

/*
* Modifiers
*/

.aa-offset-column--reversed {
	.aa-inner {

		@include bp(lg) {
			flex-direction: row;
		}
	}

	.aa-offset-column__image {
		left: auto;
		right: 0;
	}

	.aa-offset-column__body {
		@include bp(lg) {
			flex-direction: row;
		}
	}

	.aa-offset-column__text {
		margin-left: 0;

		@include bp(lg) {
			padding: 80px;
			padding-left: 0;
		}
	}

	.aa-offset-column__footer {
		.aa-video-button {
			margin-left: 15px;
		}
	}
}

.aa-offset-column--small {
	.aa-offset-column__image {
		max-width: calc(#{$full-site-width} / 12 * 7);
	}

	.aa-offset-column__text {
		max-width: calc(#{$full-site-width} / 12 * 7);

		@include bp(lg) {
			margin-top: 80px;
		}
	}
}

.aa-offset-column--large {
	.aa-offset-column__image {
		max-width: calc(#{$full-site-width} / 12 * 9);
	}

	.aa-offset-column__text {
		max-width: calc(#{$full-site-width} / 12 * 6);

		@include bp(lg) {
			margin-top: 190px;
		}
	}
}

.aa-offset-column__image--popup {
	position: relative;

	@include bp(lg) {
		position: absolute;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.08%, rgba(0, 0, 0, 0.6) 100%);
	}
}